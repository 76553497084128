import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useState, useEffect } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md"

const SimilarProducts = ({ data }) => {

    const products = [{},{},{}];
    const [currentProduct, setCurrentProduct] = useState(0);
    const nextProduct = () => setCurrentProduct((prev) => (prev + 1) % products.length)
    const prevProduct = () => setCurrentProduct((prev) => (prev - 1 + products.length) % products.length)
    
    useEffect(() => {
        const timer = setInterval(nextProduct, 5000)
        return () => clearInterval(timer)
    }, [nextProduct])
    return( 
        <div className="relative overflow-hidden">
            <div className="flex transition-transform duration-500 ease-out"
                style={{ transform: `translateX(-${currentProduct *100}%)`}}>
                    {products.map((product) => {
                        <div key={product.id} className="w-full flex-shrink-0">
                        <div className="relative h-[60vh] md:h-[70vh] lg:h-[80vh]">
                          <img
                            src={product.image}
                            alt={product.name}
                            className="w-full h-full object-cover"
                          />
                          <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center">
                            <div className="text-center text-white p-4 md:p-8 max-w-2xl">
                              <h2 className="text-3xl md:text-4xl font-bold mb-4">{product.name}</h2>
                              <p className="text-lg md:text-xl mb-6">{product.description}</p>
                              <button className="bg-white text-black font-bold py-2 px-6 rounded-full hover:bg-gray-200 transition duration-300">
                                Shop Now
                              </button>
                            </div>
                          </div>
                        </div>
                        </div>
                    })}
            </div>
            <button onClick={prevProduct}
                className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2 hover:bg-opacity-75 transition duration-300">
                    <MdChevronLeft className="w-6 h-6 text-black" />
            </button>
            <button
                onClick={nextProduct}
                className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2 hover:bg-opacity-75 transition duration-300"
                >
                <MdChevronRight className="w-6 h-6 text-black" />
            </button>
        </div>
    )
}


export default SimilarProducts