import { useMemo, useRef, useEffect, useState } from "react";
//import NextImage from "../../elements/image"
import { Loader, LoaderStatus } from "@googlemaps/js-api-loader";
//import { GoogleMap, useLoadScript } from '@react-google-maps/api';

const Retailers = ({ data }) => {
  return (
    <div className="container flex flex-col text-black">
      <h1 className="flex text-2xl font-cursive py-2">{data.title}</h1>
      <div className="w-full h-100">
        <RetailerMap retailers={data.retailers} />
      </div>
    </div>
  )
}

function RetailerMap({ center, retailers }) {
  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY
  // })
  const mapRef = useRef(null)
  const [map, setMap] = useState(null)
  const [geocoder, setGeocoder] = useState(null)
  //const geocoder = useMemo(() => new google.maps.Geocoder(), []);

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
      version: "weekly",
    });
    
    if (!loader) return;

    if (!geocoder) loader.importLibrary("geocoding")
      .then(({ Geocoder }) => {
        var g = new Geocoder()
        setGeocoder(g)
      }).catch((e) => {
        console.error(`Load Geocode was not successful for the following reason: ${e}`);
      })
    if (!map && geocoder) loader.importLibrary("maps")
      .then(({ Map }) => {
        geocoder.geocode({ address: '19951 40th PL NE, Lake Forest Park, WA, 98155'}, (results, status) => {
          var m = new Map(mapRef.current, {
            center: results[0].geometry.location,
            zoom: 12,
            mapId: 'brownednadtoasted_mapid'
          });
          setMap(m)
        }).catch((e) => {
            console.error(`Geocode was not successful for the following reason: ${e}`);
        })
      });
    if (map && geocoder) loader.importLibrary("marker").then(({ AdvancedMarkerElement }) => {
      retailers.forEach((retailer) => {
        // <div className="flex-1 text-lg" key={retailer.id}>
        //   <div className="w-10 h-10">
        //     <NextImage media={retailer.icon} />
        //   </div>
        //   <h3 className="font-bold mt-4 mb-4 font-">{retailer.name}</h3>
        //   <p>{retailer.description}</p>
        //   <p>{retailer.address}</p>
        //   <p>{retailer.city}, {retailer.state}</p>
        // </div>'
        geocoder.geocode({ address: retailer.address }, (results, status) => {
          if (status === "OK") {
            const marker = new AdvancedMarkerElement({
              position: results[0].geometry.location,
              map,
              title: retailer.name
            });
          } else {
            console.error(`Geocode ${retailer.name} was not successful for the following reason: ${status}`);
          }
        })
      })
    })
  }, [retailers, geocoder, map]);

  return <div className="h-400" ref={mapRef} />;
}


export default Retailers